/**
 * ScrollMonitor
 */

import ScrollReveal from 'scrollreveal'
// import anime from '../../../../node_modules/animejs/anime'

const scrollReveal = {
  init() {
    var slideUp = {
      distance: '100%',
      origin: 'bottom',
      opacity: 0,
      interval: 100
    }

    const triggers = document.querySelectorAll('.scroll-animate')
    if (triggers) {
      ScrollReveal().reveal(triggers, slideUp)
    }
  }
}

export default scrollReveal
